<template>
  <div style="width: 100%">
    <m-password-reset-form v-if="!done" @submit="onSubmit" />

    <div v-if="done" :class="partner.pages.password.reset.form.class">
      <p :class="partner.pages.password.reset.message">
        {{ $t('password_changed') }}
      </p>
      <router-link to="/login" :class="partner.pages.password.reset.link">
        {{ $t('click_to_log_in') }}
      </router-link>
    </div>

    <div v-if="errors" :class="partner.pages.password.reset.form.class">
      <m-banner
        v-for="(error, key) in errors.detail"
        :key="key"
        :title="$t('error.password_reset_error')"
        :message="errors.detail[key][0]"
        icon="error"
        color="negative"
      />
      <router-link to="/password/email" :class="partner.pages.password.reset.link">
        {{ $t('request_new_password') }}
      </router-link>
    </div>
  </div>
</template>

<script type="text/javascript">
import { reset } from 'api/password'
import MPasswordResetForm from './MPasswordResetForm'
import { MBanner } from 'components/'
import { mapGetters } from 'vuex'

export default {
  components: {
    MPasswordResetForm,
    MBanner
  },
  data () {
    return {
      token: this.$route.params.token,
      done: false,
      errors: null
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    })
  },
  methods: {
    async onSubmit ({ email, password, password_confirmation }) {
      try {
        await reset({
          email,
          password,
          password_confirmation,
          token: this.$route.params.token
        })
        this.errors = null
        this.done = true
      } catch (err) {
        if (err.data?.detail && typeof (err.data.detail) === 'object') {
          this.errors = err.data
        } else if (err.data?.errors) {
          this.errors = { detail: err.data.errors }
        } else {
          this.errors = {
            detail: { unexpected: [this.$t('login.password_reset_expired')] }
          }
        }
        this.done = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../styles/login.styl'
</style>
