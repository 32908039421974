<template>
  <div :class="mobileAndKinto ? 'login-mobile' : null">
    <form
      :class="form.class"
      @submit="sendResetPasswordEmail"
    >
      <p :class="form.welcomeMessage.class">
        {{ form.welcomeMessage.text || $t('enter_email_password_reset_email') }}
      </p>

      <m-banner
        v-if="validation.message"
        :title="$t('password_reset')"
        :message="$t('login.password_reset_link_sent')"
        icon="check_circle"
        color="light-blue-11"
      />

      <q-input
        v-if="!validation.message"
        v-model="email"
        :class="form.email.class"
        :dark="mobileAndKinto ? null : form.email.dark"
        :filled="form.email.filled || null"
        :square="form.email.square || false"
        :outlined="form.email.outlined || false"
        :color="mobileAndKinto ? 'black' : form.email.color"
        type="email"
        :label="form.email.label || $t('email_address')"
        input-style="text-transform:none"
      />

      <q-btn
        v-if="!validation.message"
        :color="form.resetButton.color || 'login-green'"
        type="submit"
        :size="form.resetButton.size || 'lg'"
        :class="form.resetButton.class"
        :align="form.resetButton.align || 'center'"
        :flat="form.resetButton.flat || null"
        :disable="$v.$invalid"
      >
        {{ form.resetButton.text || $t('login.send_reset_link') }}
        <q-icon v-if="form.resetButton.iconRight" align-right :name="form.resetButton.iconRight" />
      </q-btn>

      <q-btn
        :class="form.backButton.class"
        :align="form.backButton.align || 'center'"
        :flat="form.backButton.flat || null"
        :size="form.backButton.size || 'md'"
        :unelevated="form.backButton.unelevated || null"
        to="/login"
        no-caps
      >
        {{ $t('back_to_sign_in') }}
        <q-icon v-if="form.backButton.iconRight" align-right :name="form.backButton.iconRight" />
      </q-btn>
    </form>
  </div>
</template>

<script type="text/javascript">
import { email as requestEmail } from 'api/password'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { MBanner } from 'components/'
import getPartnerFromHostname from 'utils/partner'
const partner = getPartnerFromHostname()
export default {
  components: { MBanner },
  data () {
    return {
      email: null,
      validation: {
        message: null
      },
      partnerName: partner.slug,
      mobile: window.innerWidth < 640,
      form: partner.pages.password.email.form
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    emailError () {
      if (!this.$v.email.required) {
        return this.$t('validation.email.required')
      } else if (!this.$v.email.email) {
        return this.$t('validation.email')
      }
      return ''
    },
    mobileAndKinto () {
      return this.mobile && ['kinto', 'derbygo'].includes(this.partnerName)
    }
  },
  methods: {
    async sendResetPasswordEmail (e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$error) {
        try {
          await requestEmail(this.email)

          this.validation.message = true
        } catch (err) {
          this.$q.notify(this.$t('error.default'))
        }
      }
    }
  },
  validations: {
    email: { required, email }
  }
}
</script>
<style lang="stylus" scoped>
  .bp-green
    color: #009640
  @import '../../../styles/login.styl'
</style>
